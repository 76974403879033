import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-featuredcases',
  templateUrl: './featuredcases.component.html',
  styleUrls: ['./featuredcases.component.scss']
})
export class FeaturedcasesComponent implements OnInit {

  images: string[] = [
    '../../assets/img/Header.png',
    '../../assets/img/astro.png'
    // Add more image URLs as needed
  ];
  currentIndex: number = 0;
 
  constructor() { }
 
  ngOnInit(): void {
  }
 
  get currentImage(): string {
    return this.images[this.currentIndex];
  }
 
  prevImage(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.images.length - 1;
    }
  }
 
  nextImage(): void {
    if (this.currentIndex < this.images.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }
 
}

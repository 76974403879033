<div class="industcontatiner">
      
       <div class="col-md-12  indtst">
            <h4 class="industriheading">Industries</h4>
       </div>
    
    <div class="text-content">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4" >
                    <h5 class="card-title industriecol">Publisher</h5>
                    <div class="subcol"> 
                        <p class="card-text subcoltext">
                            A business strategy is the means by which it sets out to achieve its desired ends.
                        </p>
                    </div>
                </div>
                <div class="col-md-4" >
                    <h5 class="card-title industriecol1">Institution</h5>
                        <div class="subcol1">
                            <p class="card-text subcoltext1"> Restructuring your company could restore its viability and improve its liquidity position.</p>

                        </div>
                </div>
                <div class="col-md-4" >
                    <h5 class="card-title industriecol2">Societies</h5>
                        <div class="subcol2">
                            <p class="card-text subcoltext2"> Restructuring your company could restore its viability and improve its liquidity position.</p>

                        </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="col-md-12">
            <div class="row" >
                <div class="col-md-4">
                    <h5 class="card-title industriecol3">Service Provider</h5>
                    <div class="subcol"> 
                        <p class="card-text subcoltext">
                            A business strategy is the means by which it sets out to achieve its desired ends.
                        </p>
                    </div>                
                </div>
                <div class="col-md-4" >
                    <h5 class="card-title industriecol4">Government Organization</h5>
                    <div class="subcol1">
                        <p class="card-text subcoltext1"> Restructuring your company could restore its viability and improve its liquidity position.</p>
                    </div>
                </div>
                <div class="col-md-4" >
                    <h5 class="card-title industriecol5">Healthcare/Pharmaceutical</h5>
                    <div class="subcol2">
                        <p class="card-text subcoltext2"> Restructuring your company could restore its viability and improve its liquidity position.</p>

                    </div>
                </div>
            </div>
        </div>

    
    </div>
    <div class="col-md-12 text-footer">
        <div class="row">
            <div class="col-sm-8">
                <p class="indfooter">
                    Are you looking for professional advice for your business or your industry?
                </p>
            </div>
            <div class="col-sm-4">
                <div class="freequote">
                    <div class="row">
                        <div class="col-sm-2 getsym">
                            <span class="material-symbols-outlined symb">
                                near_me
                            </span>
                        </div>
                        <div class="col-sm-10 gettxt">
                            <p class="getfreequote">
                                Get a free quote
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


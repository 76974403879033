<div class="container">
    <h3 class="insights">Insights</h3>
 
    <p class="text-center para1">Read our views on the things that matter to you. And get to know our people. We make
        the difference.</p>
 
    <main class="col d-flex flex-wrap overflow-auto" >
        <div class="card m-2" style="width: 20.833333333333332vw;">
            <div class=" zoom">
                <img src="../../assets/img/stockk.png" class=" card-img-top" height="300">
 
            </div>
            <div class="card-body">
                <div style="list-style: none;line-height: 1.5vw;">
                    <p class="stock">Stock</p>
                    <p class="cardline2">US Stocks Are Expensive</p>
                    <p class="date">APRIL 11, 2023</p>
                    <p class="para">It is a long established fact that a reader will be distracted by the readable
                        content of a page when looking at its layout.</p>
                    <br><br>
                    <p class="market">Market</p>
                    <u class="underline p-1">Reture more</u>
                </div>
            </div>
        </div>
 
        <div class="card m-2" style="width: 20.833333333333332vw;">
            <div class=" zoom">
                <img src="../../assets/img/finance.jpg" class="card-img-top" height="300">
            </div>
            <div class="card-body">
                <div style="list-style: none;line-height: 1.5vw;">
                    <p class="stock">Finance, Stock</p>
                    <p class="cardline2">Investment Update, FourthQuarter 2016</p><br>
                    <p class="date">SEPTEMBER 11, 2023</p>
                    <p class="para">It is a long established fact that a reader will be distracted by the readable
                        content of a page when looking at its layout.</p>
                    <br><br>
                    <p class="market">ART, AWARDS, FESTIVAL</p>
                    <u class="underline">Reture more</u>
                </div>
            </div>
        </div>
 
        <div class="card m-2" style="width: 20.833333333333332vw;">
            <div class=" zoom">
 
                <img src="../../assets/img/market.jpg" class="card-img-top" height="300">
                </div>
                <div class="card-body">
                    <div style="list-style: none;line-height: 1.5vw;">
                        <p class="stock">Finance</p>
                        <p class="cardline2">Four Big Mistakes Your Small Business Is Making</p>
                        <p class="date">MARCH 11, 2023</p>
                        <p class="para">It is a long established fact that a reader will be distracted by the readable
                            content of a page when looking at its layout.</p>
                        <br><br>
                        <p class="market">CAHT, COMMEMNTS, CONTENT</p><br>
                        <u class="underline p-1">Reture more</u>
                    </div>
                </div>
            </div>
    </main>
 
</div>
<div>
    <div class="row">
        <div class="col-5">
            <div class="whyus">
                <img src="../../assets/img/getsitelogo.png" style="margin-left: 10vw;">    
                <p class="whyustext">
                    Why choose us?
                </p>
                <button class="contact">
                    contact us
                </button>
            </div>
        </div>
        <div class="col-1 vl"></div>
        <div class="col-6 example" style="height: 40vw;">
           
            <h2 class="numb">
                01.
            </h2>
            <h3 class="numbtext">
                Let the numbers speak!
            </h3>
            <h5 class="subnumtxt">
                With enough data, the numbers speak for themselves.
            </h5>
            <div class="row">
                <div class="col-3">
                    <h4>500+</h4>
                    <p class="txt">Successfully <span class="txt1">completed cases</span></p>
                </div>
                <div class="col-3" style="margin-left: 4vw;">
                    <h4>750+</h4>
                    <p class="txt"><b>Highly</b> specialised <span class="txt1">consultants</span></p>
                </div>
            </div>
            <h2 class="numb">
                02
            </h2>
            <p class="numbtext">
                Let the numbers speak!
            </p>
            <h2 class="numb">
                0I
            </h2>
            <p class="numbtext">
                Let the numbers speak!
            </p>
            <h2 class="numb">
                0I
            </h2>
            <p class="numbtext">
                Let the numbers speak!
            </p><h2 class="numb">
                0I
            </h2>
            <p class="numbtext">
                Let the numbers speak!
            </p>
        </div>
    </div>
</div>



   
<div class="container-fluid">
    <div class="card mt-0">
        <div >
            <h3 class="heading">Featured cases</h3>
         </div>
        <div class="card-body">
         <div class="row">
            <img class="headerflower" src="../../assets/img/Header.png">
             <div class="col-2">
            
             <button class="btn btn-primary btn-sm" style="margin: 0.3vw;" (click)="prevImage()">
                <span class="material-symbols-outlined">
                    arrow_back_ios
                </span>
            </button>
            <button class="btn btn-primary btn-sm"  (click)="nextImage()">
                <span class="material-symbols-outlined">
                    arrow_forward_ios
                </span>
            </button>
           
             </div>
             <div class="col w-50">
                <div class="row">
                    <h5 class="card-title">Pleasure</h5>
                    <p class="card-text">As chief operating officer, Jason Bradson is responsible for Financial Company's operations East and Central regions. As chief operating officer,</p>
                   </div>
                   <div class="row">
                    <button type="button" class=" btn1">Read more</button>                
    
                </div>

        </div>
       

    </div>

      </div>
</div>
</div>
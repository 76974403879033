<footer class=" footer bg-primary text-center text-lg-start" style="margin-top: 8vw;">
        <!-- Grid container -->
        <div class="container-fluid">
            <!--Grid row-->
           <div class="col-md-12">
            <div class="row">
                <!--Grid column-->
                <div class="col-md-4  mb-4 ">
                  <span class="material-symbols-outlined">move</span>        
                  <h5 class="text-uppercase">Our offices</h5>
                      <p class="para1">
                      View our locations around the world and find contact details for your nearest office.
                  </p>
                  <u class="underline">Locate the nearest office</u>
                </div>
                <!--Grid column-->
          
                <!--Grid column-->
                <div class="col-md-4  mb-4 ">
                    <span class="material-symbols-outlined">mail</span>        
                    <h5 class="text-uppercase">Drop a line</h5>
                        <p class="para1">
                            You may contact us by filling in this form any time you need professional support            </p>
                    <u class="underline1">Fill our form</u>
                  </div>
                <!--Grid column-->
                <div class="col-md-4  mb-4 ">
                    <span class="material-symbols-outlined">groups</span>        
                    <h5 class="text-uppercase">Careers</h5>
                        <p class="para1">
                            Join the world's largest consulting organization with a unique position           
                             <u class="underline2">See Open position</u>
                 
                        </div>
                <!--Grid column-->
              </div>
           </div>
            <!--Grid row-->
          </div>
          
</footer>
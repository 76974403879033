<div class="container-fluid">
  <div class="image-container">
        <div id="carouselExampleIndicators" class="carousel slide" >
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="d-block w-100 imgclass" src="assets/img/Slider1.png" alt="First slide">
              <div class="overlay">
                <div class="text-slider1">The fastest way to acheive success-</div>
                <div class="text-slid">To succeed in business today, you need to be flexible and have good planning and organizational skills</div>
                <div class="d-flex">
                  <button class="btn btn">Our services</button>
                  <button class="btn btn-primary">Watch the Presentation</button>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="d-block w-100 imgclass" src="assets/img/Slide2.png" alt="Second slide">
              <div class="overlay">
                <div class="text-slider2">Together.</div>
                <div class="text-slid2">We are shaping the future</div>
                <div class="text-slide3">
                  <ul style="list-style: none; font-size: 1.3020833333333333vw;">
                      <li>
                        <img src="../../assets/img/Check_mark.png" style="width: 1vw; height: 1vw;">
                        Strategy & Planning</li>
                    <li>
                      <img src="../../assets/img/Check_mark.png" style="width: 1vw; height: 1vw;">
                      Finance & Restructuring
                    </li>
                    <li>
                      <img src="../../assets/img/Check_mark.png" style="width: 1vw; height: 1vw;">
                      Audit & Evaluation</li>
                    <li>
                      <img src="../../assets/img/Check_mark.png" style="width: 1vw; height: 1vw;">
                      Taxes & Efficiency</li>
                  </ul>
                </div>
                <div class="d-flex">
                  <button class="btn btn1">Our services</button>
                  <button class="btn2 btn-primary">Case studies</button>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="d-block w-100 imgclass" src="assets/img/Slide3.png" alt="Third slide">
              <div class="overlay">
                <div class="text-slider4">Keep up to Date</div>
                <div class="text-slid4">There was a time when keeping up with the news meant subscribing to your local newspaper</div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
  </div>
  <div class="col-md-12 maincontent">
      <div class="text-content">
          <p>
              We bring the right people together to challenge established thinking and drive transformation.
          </p>
      </div>
  </div>
  <div class="maincontent1" >
      <div class="row">
          <div class="innovative  me-3">
              
                  <div class="">
                      <span class="material-symbols-outlined" style="margin-left: 13vw;position: static;">
                          emoji_objects
                          </span>
                      <p class=" inovatetext">We Innovate</p>
                    <p class=" innovatesub">We innovate systematically, continuously and successfully.</p>
                    <a href="#" class="readmore">Read More</a>
                  </div>
              
          </div>
          <div class="performance me-3">
              
                  <div class="">
                      <span class="material-symbols-outlined" style="margin-left: 13vw;position: static;">
                          monitoring
                          </span> 
                    <p class=" inovatetext">Performance</p>
                    <p class=" innovatesub">Performance is about solving problems and building business.</p>
                    <a href="#" class="readmore">Read More</a>
                  </div>
              
          </div>
          <div class="servicetext me-3">
              
                  <div class="">
                      <span class="material-symbols-outlined" style="margin-left: 13vw;position: static;">
                          home_storage
                          </span>
                    <p class=" inovatetext">A Full Service</p>
                    <p class="innovatesub">We are a full service business solutions provider.</p>
                    <a href="#" class="readmore">Read More</a>
                  </div>
          </div>
      </div>
  </div>
  <!-- <div class="col-md-12 homesection">

  </div> -->
  <section class="homesection">
      <div class="row">
        <div class="col-5">
          <div class="section1">
            <p class="seccontent">Wealth Management </p>
          </div>
          <div class="subsection">
            <p class="seccontent1">
              Wealth management is a high-level professional service that combines financial and investment advice, accounting and tax services, retirement planning and legal or estate planning for one set fee.
            </p>
            <button class="contact">
               Read more
          </button>
          </div>
        </div>
        <div class="col-5">
            <img src="assets/img/secimg.jpg" class="secimg">
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <img src="assets/img/secimg.jpg" class="secimg2">
         
        </div>
        <div class="col-5">
           
            <div class="section2">
              <p class="seccontent2">Investment Management</p>
            </div>
            <div class="subsection">
              <p class="seccontent1">
                Wealth management is a high-level professional service that combines financial and investment advice, accounting and tax services, retirement planning and legal or estate planning for one set fee.
              </p>
              <button class="contact">
                Read more
           </button>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="section3">
            <p class="seccontent">Healthcare Consulting</p>
          </div>
          <div class="subsection">
            <p class="seccontent1">
              Wealth management is a high-level professional service that combines financial and investment advice, accounting and tax services, retirement planning and legal or estate planning for one set fee.
            </p>
            <button class="contact">
              Read more
         </button>
          </div>
        </div>
        <div class="col-5">
            <img src="assets/img/secimg.jpg" class="secimg3">
        </div>
      </div>
  </section>
  <app-industries></app-industries>
  <app-about></app-about>
  <app-featuredcases></app-featuredcases>
  <app-insights></app-insights>
  <app-footer></app-footer>
</div>
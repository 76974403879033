<nav class="navbar navbar-expand-lg navbar-light bg-body-tertiary navheader">
    <!-- Container wrapper -->
    <div class="container-fluid">
      <!-- Toggle button -->
      <a class="navbar-brand" href="#">
        <img src="../../assets/img/logo.png" class="imglogo" alt="Icodex">
        </a>
      <!-- <button data-mdb-collapse-init class="navbar-toggler"
        type="button"
        data-mdb-target="#navbarCenteredExample"
        aria-controls="navbarCenteredExample"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i class="fas fa-bars"></i>
      </button> -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- Collapsible wrapper -->
      
      <!-- <div class="collapse navbar-collapse justify-content-center navmenu" id="navbarCenteredExample"> -->
        <div class="collapse navbar-collapse justify-content-center navmenu" id="navbarNavDropdown">

        <!-- Left links -->
        <ul class="navbar-nav mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="/home">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/services">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" >Services</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" >Case Studies</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" >Insights</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" >Pages</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" >Contacts</a>
          </li>
          
          <!-- Navbar dropdown -->
          <!-- <li class="nav-item dropdown">
            <a
              data-mdb-dropdown-init
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              aria-expanded="false"
            >
              Dropdown
            </a>
           
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a class="dropdown-item" href="#">Action</a>
              </li>
              <li>
                <a class="dropdown-item" href="#">Another action</a>
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item" href="#">Something else here</a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled"
              >Disabled</a
            >
          </li> -->
        </ul>
        <!-- Left links -->
      </div>
      <!-- Collapsible wrapper -->
    </div>
    <!-- Container wrapper -->
  </nav>
